.asvl {
  margin: 2rem auto;
}

.asvlNavLink {
  display: block;
  text-align: center;
}

.asvlHeadings {
  margin: auto;
  text-align: center;
  padding: 1rem;
}

h2:first-letter {
  text-transform: capitalize;
}

.informasjon,
.studier,
.metoder {
  margin: 1rem auto 2rem auto;
  padding: 0.5em;
  border-radius: 1em;
}

.dbNav {
  margin: auto;
  display: flex;
  justify-content: center;
  text-decoration: none;
}

summary {
  font-size: 1.5rem;
  padding: 1rem;
  cursor: pointer;
}

@keyframes open {
  0% {
    transform: 0%;
    opacity: 0.5;
  }
  100% {
    opacity: 1;
    transform: 100%;
  }
}

details[open] summary ~ * {
  animation: open 0.5s ease-in-out;
}
