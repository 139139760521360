* {
  margin: 0;
  padding: 0;
  line-height: 1.5rem;
}

html,
.app-container,
.app-nav {
  background: var(--main-bg-color);
}

:root {
  --main-bg-color: hsla(200, 69%, 90%, 1);
  --main-head-color: hsla(200, 69%, 25%, 0.9);
  --border-color: hsla(200, 69%, 99%, 1);
  --border-color-active: hsla(200, 69%, 60%, 1);
  --navbar-color-hover: hsla(150, 50%, 80%, 0.8);
  --main-head-color-hover: hsla(200, 69%, 10%, 0.9);
}

a:link {
  color: var(--main-head-color);
}

a:visited {
  color: var(--main-head-color);
}

a:hover,
a:active {
  background: var(--navbar-color-hover);
  color: var(--main-head-color-hover);
}

.app-container {
  display: grid;
  grid-template-rows: 2rem auto;
  grid-template-columns: auto;
  grid-template-areas:
    'header'
    'content';
}

.app-header {
  grid-area: header;
  text-align: center;
  font: 2rem Georgia, serif;
}

.app-header a {
  color: var(--main-head-color);
}

.app-nav {
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 2.5rem;
  display: flex;
  justify-content: space-evenly;
}

.app-content {
  margin: 0.5rem;
  grid-area: content;
  padding-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
}

.navbarLink {
  flex-grow: 1;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid white;
  line-height: 100%;
  font-size: 1.5rem;
  padding: 0.5rem;
  margin: auto;
  color: var(--main-head-color);
}

.navbarLink--active {
  background: var(--navbar-color-hover);
  color: var(--main-head-color-hover);
}

.box {
  width: 95%;
  min-height: 100px;
  max-width: 600px;
  padding: 1rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
}

.box p {
  padding: 1rem;
}

.border {
  border-radius: 1rem;
  border: 5px solid var(--border-color);
}
.shadow {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.buzzword {
  color: var(--main-head-color-hover);
  border-bottom: 3px solid var(--main-head-color);
}

.contact-form {
  width: 95%;
  max-width: 600px;
  padding: 0;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.contact-form label {
  display: block;
  font-size: 1.2rem;
  background: none;
  margin-top: 0.5rem;
}

.contact-form textarea {
  min-height: 5rem;
}

.input-field {
  font-size: 1rem;
  padding: 0.1rem 0.5rem 0.1rem 0.5rem;
  margin: 0 auto 0.5rem auto;
  width: 85%;
  max-width: 400px;
}

.submit {
  margin: 0 auto 1rem auto;
  font-size: 1.5rem;
  padding: 1rem;
  display: block;
}

.leaderboard {
  font-size: 2rem;
  padding: 2rem;
}

.codHighscoreEntry {
  min-height: 30px;
  min-width: 80%;
  color: white;
  padding: 1rem;
  margin: 0.75rem auto 0.75rem auto;
}

.playerWins {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  -webkit-text-stroke: 1px black;
}

.playerStats {
  font-size: 1.5rem;
  color: black;
}

.playerStats > * {
  margin-bottom: 0.5rem;
}

.gold {
  background: linear-gradient(
    to right,
    #bf953f,
    #fcf6ba,
    #b38728,
    #fbf5b7,
    #aa771c
  );
}

.silver {
  background: linear-gradient(
    to right,
    rgb(211, 206, 206),
    #f3f2e2,
    silver,
    #f3f2e2,
    rgb(150, 146, 146)
  );
}

.bronze {
  background: linear-gradient(
    to right,
    #a15e05,
    white,
    #a15e05,
    white,
    #744303
  );
}

.notTopThree {
  background: lightgreen;
}

summary:focus {
  outline: none;
}

@media only screen and (min-width: 650px) {
  .app-container {
    grid-template-rows: 2.5rem 2.5rem auto;
    grid-template-columns: auto;
    grid-template-areas:
      'header'
      'nav'
      'content';
  }

  .app-content {
    padding: 0;
    margin: 1rem;
  }

  .app-nav {
    position: relative;
  }

  .navbarLink:hover {
    transition-duration: 0.2s;
    transform: scale(1.1) translateY(-2px) rotate(-2deg);
    background: var(--navbar-color-hover);
    color: var(--main-head-color-hover);
  }

  .navbarLink--active:hover {
    color: var(--main-head-color-hover);
  }

  .border:hover {
    border: 5px solid var(--navbar-color-hover);
  }
}
